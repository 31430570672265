@import "~rsuite/styles/index.less";

// @enable-css-reset: false;

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
}

.rs-content {
  margin: 20px;
  padding: 20px;
}

.rs-navbar-default .rs-navbar-item:hover {
  background-color: transparent;
  color: inherit;
}

.rs-drawer-backdrop {
  top: 56px;
}
.rs-drawer-wrapper {
  top: 56px;

  .rs-drawer {
    margin-top: 56px;

    .rs-drawer-content {
      height: calc(100% - 56px);
    }
  }
}

.nav-filters {
  button {
    svg {
      background-color: var(--rs-btn-default-bg) !important;
    }

    &:hover {
      background-color: var(--rs-btn-default-bg) !important;
      color: var(--rs-btn-default-text) !important;
    }
  }
}
.rs-drawer-body {
  padding: 30px;
}
.picker-group {
  cursor: initial;

  margin-bottom: 15px;

  span {
    background-color: var(--rs-input-bg);
  }
  .rs-picker {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0px;
  }
}
