.screen--login {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;

    align-items: center;
  }
}
